<template>
	<div>
				<!-- ======= Header ======= -->
		<header id="header" class="fixed-top">
			<div class="container d-flex">

				<div class="logo mr-auto">
					<!--<h1 class="text-light"><a href="index.html">Sole</a></h1>-->
					<!-- Uncomment below if you prefer to use an image logo -->
					<a href="index.html"><img src="/static/img/sole_tracking.png" alt="" class="img-fluid"></a>
				</div>

				<nav class="nav-menu  d-lg-block">
					<ul>
						<li class="d-none active"><a href="#header">Inicio</a></li>
						<li class="d-none"><a href="#features">¿Qué es SoLe?</a></li>
						<li class="d-none"><a href="#pricing">Comprar</a></li>
						<li class="d-none"><a href="#gallery">App SoLe</a></li>          
						<li class="d-none"><a href="#faq">Preguntas frecuentes</a></li>
						<li class="d-none"><a href="#contact">Contacto</a></li>
						<li v-if="!user.token" class="get-started"><router-link to="/login">Iniciar Sesión</router-link></li>
						<li v-if="!user.token" ><router-link to="/register">Registrate</router-link></li>
						<li class="d-none" v-if="user.token">¡Hola {{user.nombre}}!</li>
						<li v-if="user.token"><router-link to="/plataforma">Plataforma</router-link></li>
					</ul>
				</nav><!-- .nav-menu -->

			</div>
		</header><!-- End Header -->

		<!-- ======= Hero Section ======= -->
		<section id="hero" class="d-flex align-items-center">

			<div class="container">
				<div class="row">
					<div class="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
						<div>
							<h1>SOLE Tracking</h1>
							<h2>El dispositivo que te mantente informado de donde se encuentran tus familiares, amigos, mascotas, vehículos, etc. desde cualquier lugar</h2>
							<!--<a href="#pricing" class="buy-btn">COMPRAR</a>-->
							<a href="#features" class="buy-btn">Conocer más</a>
						</div>
					</div>
					<div class="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
						<img src="/static/img/hero-img.png" class="img-fluid" alt="">
					</div>
				</div>
			</div>

		</section><!-- End Hero -->

			<!--<main id="main">-->

				<!-- ======= App Features Section ======= -->
				<section id="features" class="features">
					<div class="container">

						<div class="section-title">
							<h2>¿Qué es SoLe?</h2>
							<p class="standardText">SOLE es un dispositivo de localización por GPS <strong>100% mexicano</strong> que busca servir como herramienta de seguridad para tus familiares, amigos, mascotas o lo que tú quieras, y los puedes monitorear de forma segura en nuestra plataforma en línea</p>
						</div>

						<div class="row no-gutters">
							<div class="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
								<div class="content d-flex flex-column justify-content-center">
									<div class="row">
										<div class="col-md-6 icon-box" data-aos="fade-up">
											<i class="bx bx-map"></i>
											<h4>Localización GPS</h4>
											<p>Registra cual es la última ubicación en la que se ha encontrado el portador del dispositivo</p>
										</div>
										<div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
											<i class="bx bx-battery"></i>
											<h4>Batería</h4>
											<p>Brinda hasta 32 horas de autonomía</p>
										</div>
										<div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
											<i class="bx bx-radar"></i>
											<h4>Gran cobertura</h4>
											<p>En zonas con poca señal satelital ofrece una ubicación apróximada por medio de telemetría de antenas</p>
										</div>
										<div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
											<i class="bx bx-devices"></i>
											<h4>Interfaz amigable</h4>
											<p>Constantemente mejorando para que la plataforma cubra óptimamente las necesidades de nuestros usuarios</p>
										</div>
										<div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
											<i class="bx bx-home-heart"></i>
											<h4>Conecte más de un dispositivo</h4>
											<p>Podrá registrar todos sus dispositivos en su cuenta, permitiendo el monitoreo de múltiples portadores</p>
										</div>
										<div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
											<i class="bx bx-street-view"></i>
											<h4>Autónomo</h4>
											<p>No requiere de conexión a smartphones o dispositivos adicionales, lo que le permite funcionar como dispositivo independiente y una conexión exclusiva a nuestros servidores</p>
										</div>
										<!--<div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
											<i class="bx bx-id-card"></i>
											<h4>Explicabo consectetur</h4>
											<p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
										</div>-->
									</div>
								</div>
							</div>
							<div class="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
								<img src="/static/img/features.svg" class="img-fluid" alt="">
							</div>
						</div>

					</div>
				</section><!-- End App Features Section -->

				<!-- ======= Details Section ======= -->
				<section id="details" class="details">
					<div class="container">

						<div class="row content">
							<div class="col-md-4" data-aos="fade-right">
								<img src="/static/img/details-1.png" class="img-fluid" alt="">
							</div>
							<div class="col-md-8 pt-4" data-aos="fade-up">
								<h3>La información es tu mejor herramienta</h3>
								<p class="standardText">
									Buscamos proporcionar nuestra plataforma como herramienta a nuestros usuarios para que cuenten con dicha información sobre la última ubicación en caso de ser necesario para apoyar en la búsqueda de algún familiar extraviado
								</p>
								<p class="standardText">
									La información es únicamente accesible por el dueño de el/los dispositivos asociados a su cuenta y no almacenamos posiciones anteriores a la última para ofrecer la privacidad y seguridad que necesitas
								</p>
							</div>
						</div>

						<div class="row content">
							<div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
								<img src="/static/img/details-2.png" class="img-fluid" alt="">
							</div>
							<div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
								<h3>Plataforma amigable</h3>
								<p class="standardText">
									Buscamos brindar la mejor experiencia de usuario en nuestra plataforma creando una interfaz simple y funcional que te permita ver la información de tus dispositivos
								</p>
								<p class="standardText">
									Ponemos a tu disposición un mapa con marcadores de la última ubicación de tus dispositivos, puedes personalizar los colores y fotografías para distinguirlos
								</p>
							</div>
						</div>

						<div class="row content">
							<div class="col-md-4" data-aos="fade-right">
								<img src="/static/img/details-3.png" class="img-fluid" alt="">
							</div>
							<div class="col-md-8 pt-5" data-aos="fade-up">
								<h3>Hardware mexicano</h3>
								<p class="standardText">
									Nosotros nos encargamos del diseño electrónico de nuestros dispositivos así como de su programación lo que no solo nos enorgullece si no que brinda varios beneficios:
								</p>
								<ul class="standardText">
									<li><i class="icofont-check"></i> Mejora constante del producto</li>
									<li><i class="icofont-check"></i> Correcciones de posibles fallas</li>
									<li><i class="icofont-check"></i> Asistencia técnica</li>
								</ul>
								<p class="standardText">
									Trabajamos para ofrecerte un producto de calidad 
								</p>
							</div>
						</div>
					</div>
				</section><!-- End Details Section -->

				<!-- ======= Pricing Section ======= 
				<section id="pricing" class="pricing">
					<div class="container">

						<div class="section-title">
							<h2>Adquiere tu SOLE Tracking</h2>
							<p class="standardText">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
						</div>

						<div class="row no-gutters">

							<div class="col-lg-5 box" data-aos="fade-right">
								<h3>Kids</h3>
								<h4>$2,600.00MXN<span>+ suscripción después del primer año</span></h4>
								<ul>
									<li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
									<li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
									<li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
									<li class="na"><i class="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
									<li class="na"><i class="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li>
								</ul>
								<router-link to="payment" class="get-started-btn">Comprar</router-link>
							</div>

							<div class="col-lg-2">
								
							</div>

							<!--<div class="col-lg-4 box featured" data-aos="fade-up">
								<h3>Business</h3>
								<h4>$29<span>per month</span></h4>
								<ul>
									<li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
									<li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
									<li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
									<li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
									<li><i class="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
								</ul>
								<a href="#" class="get-started-btn">Get Started</a>
							</div>

							<div class="col-lg-5 box" data-aos="fade-left">
								<h3>Car</h3>
								<h4>$2,000.00MXN<span>+ suscripción después del primer año</span></h4>
								<ul>
									<li><i class="bx bx-check"></i> Quam adipiscing vitae proin</li>
									<li><i class="bx bx-check"></i> Nec feugiat nisl pretium</li>
									<li><i class="bx bx-check"></i> Nulla at volutpat diam uteera</li>
									<li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
									<li><i class="bx bx-check"></i> Pharetra massa massa ultricies</li>
								</ul>
								<router-link to="payment" class="get-started-btn">Comprar</router-link>
							</div>

						</div>

					</div>
				</section> End Pricing Section -->

				<!-- ======= Gallery Section ======= -->
				<section id="gallery" class="gallery">
					<div class="container">

						<div class="section-title">
							<h2>Plataforma SOLE</h2>
							<p>Accede a nuestra plataforma en línea por medio de nuestra aplicación móvil o también vía web</p>
						</div>

						<div class="owl-carousel gallery-carousel" data-aos="fade-up">
							<a href="/static/img/gallery/gallery-1.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-1.png" alt=""></a>
							<a href="/static/img/gallery/gallery-2.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-2.png" alt=""></a>
							<a href="/static/img/gallery/gallery-3.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-3.png" alt=""></a>
							<a href="/static/img/gallery/gallery-4.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-4.png" alt=""></a>
							<a href="/static/img/gallery/gallery-5.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-5.png" alt=""></a>
							<a href="/static/img/gallery/gallery-6.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-6.png" alt=""></a>
							<!--<a href="/static/img/gallery/gallery-7.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-7.png" alt=""></a>
							<a href="/static/img/gallery/gallery-8.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-8.png" alt=""></a>
							<a href="/static/img/gallery/gallery-9.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-9.png" alt=""></a>
							<a href="/static/img/gallery/gallery-10.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-10.png" alt=""></a>
							<a href="/static/img/gallery/gallery-11.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-11.png" alt=""></a>
							<a href="/static/img/gallery/gallery-12.png" class="venobox" data-gall="gallery-carousel"><img src="/static/img/gallery/gallery-12.png" alt=""></a>-->
						</div>

					</div>
					<div id="download-btn">
						<a href="#" class="download-btn"><i class="bx bxl-play-store"></i> Google Play</a>
						<a href="#" class="download-btn"><i class="bx bxl-apple"></i> App Store</a>
						<a href="/plataforma" class="download-btn"><i class="bx bx-world"></i> Web</a>
					</div>
				</section><!-- End Gallery Section -->

				<!-- ======= Testimonials Section ======= -->
	    <!--<section id="testimonials" class="testimonials section-bg">
	      <div class="container">

	        <div class="section-title">
	          <h2>Testimonials</h2>
	          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
	        </div>

	        <div class="owl-carousel testimonials-carousel" data-aos="fade-up">

	          <div class="testimonial-wrap">
	            <div class="testimonial-item">
	              <img src="/static/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
	              <h3>Saul Goodman</h3>
	              <h4>Ceo &amp; Founder</h4>
	              <p>
	                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
	                Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
	                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
	              </p>
	            </div>
	          </div>

	          <div class="testimonial-wrap">
	            <div class="testimonial-item">
	              <img src="/static/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
	              <h3>Sara Wilsson</h3>
	              <h4>Designer</h4>
	              <p>
	                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
	                Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
	                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
	              </p>
	            </div>
	          </div>

	          <div class="testimonial-wrap">
	            <div class="testimonial-item">
	              <img src="/static/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
	              <h3>Jena Karlis</h3>
	              <h4>Store Owner</h4>
	              <p>
	                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
	                Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
	                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
	              </p>
	            </div>
	          </div>

	          <div class="testimonial-wrap">
	            <div class="testimonial-item">
	              <img src="/static/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
	              <h3>Matt Brandon</h3>
	              <h4>Freelancer</h4>
	              <p>
	                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
	                Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
	                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
	              </p>
	            </div>
	          </div>

	          <div class="testimonial-wrap">
	            <div class="testimonial-item">
	              <img src="/static/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
	              <h3>John Larson</h3>
	              <h4>Entrepreneur</h4>
	              <p>
	                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
	                Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
	                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
	              </p>
	            </div>
	          </div>

	        </div>

	      </div>
	  </section>--><!-- End Testimonials Section -->

	  

	  <!-- ======= Frequently Asked Questions Section ======= -->
	  <section id="faq" class="faq section-bg">
	  	<div class="container">

	  		<div class="section-title">

	  			<h2>Preguntas frecuentes</h2>
	  			<p class="standardText">Respondemos aquí algunas de las preguntas frecuentes, en caso de tener otra por favor contactenos vía redes sociales o en el formulario de <a href="#contact">contacto</a></p>
	  		</div>

	  		<div class="accordion-list">
	  			<ul>
	  				<li data-aos="fade-up" data-aos-delay="400">
	  					<i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#accordion-list-5" class="collapsed">¿Quién tiene acceso a la ubicación de mi dispositivo? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
	  					<div id="accordion-list-5" class="collapse" data-parent=".accordion-list">
	  						<p>
	  							Únicamente la cuenta desde la que se da de alta el dispositivo podrá visualizar y editar la información del dispositivo
	  						</p>
	  					</div>
	  				</li>

	  				<li data-aos="fade-up">
	  					<i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#accordion-list-1">¿Para que es la suscripción anual? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
	  					<div id="accordion-list-1" class="collapse show" data-parent=".accordion-list">
	  						<p>
	  							Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
	  						</p>
	  					</div>
	  				</li>

	  				<li data-aos="fade-up" data-aos-delay="100">
	  					<i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#accordion-list-2" class="collapsed">¿Mi dispositivo funciona en el extranjero? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
	  					<div id="accordion-list-2" class="collapse" data-parent=".accordion-list">
	  						<p>
	  							Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
	  						</p>
	  					</div>
	  				</li>

	  				<li data-aos="fade-up" data-aos-delay="200">
	  					<i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#accordion-list-3" class="collapsed">¿Qué sucede si mi dispositivo deja de funcionar? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
	  					<div id="accordion-list-3" class="collapse" data-parent=".accordion-list">
	  						<p>
	  							Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
	  						</p>
	  					</div>
	  				</li>

	  				<li data-aos="fade-up" data-aos-delay="300">
	  					<i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#accordion-list-4" class="collapsed">¿Mi dispositivo funciona si no pago la suscripción anual? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
	  					<div id="accordion-list-4" class="collapse" data-parent=".accordion-list">
	  						<p>
	  							Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
	  						</p>
	  					</div>
	  				</li>

	  				

	  			</ul>
	  		</div>

	  	</div>
	  </section><!-- End Frequently Asked Questions Section -->

	  <!-- ======= Contact Section ======= -->
	  <section id="contact" class="contact">
	  	<div class="container">

	  		<div class="section-title">
	  			<h2>Contacto</h2>
	  			<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
	  		</div>

	  		<div class="row">

	  			<div class="col-lg-6">
	  				<div class="row space">
	  					<div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="100">
	  						<a href="https://api.whatsapp.com/send?phone=5213325507867"><i class="bx bxl-whatsapp"></i></a>
	  						<h4>Whatsapp</h4>
	  						<p>+521 33 2550 7867</p>
	  					</div>
	  					<div class="col-lg-6 info" data-aos="fade-up" data-aos-delay="200">
	  						<i class="bx bx-envelope"></i>
	  						<h4>Correos</h4>
	  						<p>contacto@soletracking.com</p>
	  						<p>ventas@soletracking.com</p>
	  					</div>
	  				</div>
	  			</div>

	  			<div class="col-lg-6">
	  				<!--<form action="forms/contact.php" method="post" role="form" class="php-email-form" data-aos="fade-up">-->
	  				<form role="form" class="php-email-form">
	  					<div class="form-group">
	  						<input placeholder="Nombre" type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Ingresa mínimo 4 caracteres" v-model="form.name" />
	  						<div class="validate"></div>
	  					</div>
	  					<div class="form-group">
	  						<input placeholder="Correo" type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Ingresa un correo electrónico válido" v-model="form.email" />
	  						<div class="validate"></div>
	  					</div>
	  					<div class="form-group">
	  						<input placeholder="Asunto" type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Ingresa mínimo 8 caracteres" v-model="form.subject" />
	  						<div class="validate"></div>
	  					</div>
	  					<div class="form-group">
	  						<textarea placeholder="Mensaje" class="form-control" name="message" rows="5" data-rule="required" data-msg="Escribenos para comunicarnos contigo" v-model="form.message"></textarea>
	  						<div class="validate"></div>
	  					</div>
	  					<div class="mb-3">
	  						<div class="loading">Cargando</div>
	  						<div class="error-message"></div>
	  						<div class="sent-message">Tu mensaje ha sido enviado. Gracias por comunicarte!</div>
	  					</div>
	  					<div class="text-center"><button type="button" v-on:click="sendContactForm();">Enviar mensaje</button></div>
	  				</form>
	  			</div>

	  		</div>

	  	</div>
	  </section><!-- End Contact Section -->

	<!--</main>--><!-- End #main -->

<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
	</div>
</template>

<script type="text/javascript">
	export default {
	name: 'principal',
	data() {
		return {
			form: {
				name: "",
				email: "",
				subject: "",
				message: ""
			},
			send: "",
			user: {}
		}
	},
	mounted: function(){
        this.currentUser();
    },

	methods: {
		sendContactForm : function() {
			var instance = this;

			axios.request({
				url: '/api/contact',
				method: 'post',
				data: instance.form
			}).then(function(response){
				if (response.data.status) {
					instance.form.name = "";
					instance.form.email = "";
					instance.form.subject = "";
					instance.form.message = "";
					instance.send = "Enviado!"
				}else {
					console.log("Error!");
				}
			}).catch(function(error){
				console.log("Intenta de nuevo!");
			});
		},

        currentUser: function(){
            let localUser = localStorage.getItem('user');

            if(localUser != null){
                this.user = JSON.parse(localUser);
            }
        }
    

	}
}
</script>
<style scoped type="text/css" src="../../assets/css/style.css"></style>